<template>
  <div id="CustomerData">
    <h2 style="text-align: center">个人信息</h2>
    <el-form
      :model="customerData"
      :rules="rules"
      ref="customerData"
      label-width="100px"
      style="width: 400px; margin: 0 auto"
    >
      <el-form-item label="用户" prop="name">
        <el-input v-model="customerData.name"></el-input>
      </el-form-item>
      <el-form-item label="QQ" prop="QQ">
        <el-input v-model="customerData.txqq"></el-input>
      </el-form-item>
      <el-form-item label="微信" prop="weChat">
        <el-input v-model="customerData.weChat"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="Email">
        <el-input v-model="customerData.Email"></el-input>
      </el-form-item>
      <el-form-item label="手机" prop="phone">
        <el-input v-model="customerData.phone"></el-input>
      </el-form-item>
      <el-form-item label="部门" >
        <el-input v-model="customerData.deptName" disabled></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button type="primary" 
      :loading="isFlag"
      @click="srueCustomeDataBox('customerData')">确定</el-button>
    </div>
  </div>
</template>

<script>
import {srueCustomeDataBox} from './api.js'
export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      let phone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      if (!value) {
        return callback(new Error("手机不能为空"));
      }
      if (!phone.test(value)) {
        return callback(new Error("手机号码有误，请重填"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.customerData.pass !== "") {
          this.$refs.customerData.validateField("pass");
        }
        callback();
      }
    };
    return {
      isFlag:false,
      search: "",
      userAccountNum:"",
      customerData: {
      
  
        phone: "",
        name: "",
        txqq: "",
        weChat: "",
        Email: "",
        tel: "",
        deptName:""
      },
      rules: {

        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 17,
            message: "长度在 3 到 17 个字符",
            trigger: "blur",
          },
        ],
        Email: [{ type: "email", message: "请输入邮箱", trigger: "blur" }],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
      },
      addCustomerDataBox: false,
    };
  },
  methods: {
  
    getInfo() {
      
      let userId = this.$store.state.userInfo.userId;
      console.log(userId);
      this.$request({
        url: `/offerSystem/home/userMsg/${userId}`,
      }).then((res) => {
        console.log("res=========", res);
        this.customerData.name = res.userName;
        this.customerData.phone = res.userPhone;
        this.customerData.txqq = res.qq;
        this.customerData.Email = res.email;
        this.customerData.weChat = res.wechat;
        this.userAccountNum = res.userAccountNum;
        this.customerData.deptName = res.deptName
      });
    },

    async srueCustomeDataBox() {
      const res = await srueCustomeDataBox({
        userId:this.$store.state.userInfo.userId,
        userAccountNum:this.userAccountNum,
        userName:this.customerData.name,
        qq:this.customerData.txqq,
        wechat:this.customerData.weChat,
        email:this.customerData.Email,
        userPhone:parseInt(this.customerData.phone),
      })
      this.isFlag = true
      setTimeout(()=>{
        this.isFlag = false
      },2000)
    },
  
 
},
mounted(){
  this.getInfo()
}
};
</script>

<style lang="less" scoped>
.top {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
</style>